import React, { FunctionComponent } from 'react'
import { InputSize, TestProps } from '@wh-components/core/common'
import { SearchCallback } from '../SearchCallback'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { TypographyProps } from '@wh-components/system/typography'
import { BaseSelect, SelectItem } from '@wh-components/core/FormElements/Select/Select'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { removeUrlParameter } from '@wh/common/chapter/lib/urlHelpers'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'

interface AdsPerPageProps extends Required<TestProps> {
    onSearch: SearchCallback
    rowsRequested?: number
    searchResult: SearchResult
    label?: string
    size?: InputSize
    fontSize?: TypographyProps['fontSize']
}

const getOptions = (defaultPageSize: number): SelectItem[] => {
    return [
        { label: String(defaultPageSize), value: String(defaultPageSize) },
        { label: String(defaultPageSize * 2), value: String(defaultPageSize * 2) },
        { label: String(defaultPageSize * 3), value: String(defaultPageSize * 3) },
    ]
}

export const AdsPerPage: FunctionComponent<AdsPerPageProps> = ({
    testId,
    onSearch,
    rowsRequested,
    searchResult,
    label = 'Anzeigen pro Seite',
    size = 'small',
    fontSize = 's',
}) => {
    const triggerPageSearch = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const nRows = e.currentTarget.value
        const contextLink = findContextLinkWithId('searchBaseLink', searchResult.searchContextLinks)
        if (contextLink?.relativePath) {
            contextLink.relativePath = removeUrlParameter(contextLink.relativePath, 'rows')
            await onSearch(contextLink, { rows: nRows })
        }
    }

    const defaultPageSize = searchResult.metaData?.defaultPageSize ? searchResult.metaData?.defaultPageSize : 25 // 25 as fallback until the value comes from iAd

    return searchResult.rowsFound > defaultPageSize ? (
        <Box display="flex" flexDirection={{ phone: 'column', tablet: 'row' }} alignItems={{ tablet: 'center' }}>
            {label && (
                <Text
                    as="label"
                    htmlFor={testId}
                    fontSize={fontSize}
                    fontWeight="semibold"
                    marginRight={{ tablet: 's' }}
                    marginBottom={{ phone: 'xs', tablet: 0 }}
                >
                    {label}
                </Text>
            )}
            <BaseSelect
                id={testId}
                testId={testId}
                size={size}
                items={getOptions(defaultPageSize)}
                value={getOptions(defaultPageSize).find((item) => item.value === String(rowsRequested))?.value}
                onChange={triggerPageSearch}
            />
        </Box>
    ) : null
}
