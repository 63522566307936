import React, { Fragment, FunctionComponent, useContext } from 'react'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { Button, ButtonSizeType } from '@wh-components/core/Button/Button'
import Searchagent from '@wh-components/icons/Searchagent'
import { redirectToLoginPage } from '@wh/common/chapter/lib/routing/bbxRouter'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { OPEN_CREATE_SEARCH_AGENT_MODAL_STORAGE_KEY } from '@wh/common/chapter/lib/localStorage'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { ResponsiveValue } from '@wh-components/system'
import { storageAvailable } from '@wh/common/chapter/lib/storageAvailable'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { isUserLoggedIn, useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { hideWhenKeyboardIsOpen } from '@wh/common/chapter/lib/hideWhenKeyboardIsOpen'
import { useIsAndroidBrowser } from '@wh/common/chapter/components/AndroidOnelinkBanner/useAndroidOnelinkBanner'
import { useOptimizelyTrack } from '@wh/common/chapter/hooks/optimizely'
import { SearchAgentOptions } from '@bbx/common/types/searchAgent'
import { getSearchAgentOptions } from '@bbx/search-journey/common/api/searchAgentApiClient'

export interface SearchAgentUIState {
    state: SearchAgentUIStateOption
    searchAgentOptions?: SearchAgentOptions
}

export type SearchAgentUIStateOption = 'idle' | 'showModal'

type ButtonProps = React.ComponentProps<typeof Button>

export interface CreateSearchAgentButtonProps {
    searchAgentCreateLink: ContextLink | undefined
    searchAgentOptionsLink: ContextLink | undefined
    searchAgentUIState?: SearchAgentUIState
    setSearchAgentUIState: (value: SearchAgentUIState) => void
    testId?: string
    buttonVariant?: 'button' | 'link'
    clickActionEvent:
        | Extract<
              TaggingActionEvent,
              | 'search_result_list_search_agent_click_top'
              | 'search_result_list_search_agent_click_sticky'
              | 'search_result_list_search_agent_click_bottom'
              | 'search_result_list_search_agent_click_empty_result'
              | 'search_result_list_search_agent_click_sticky_small'
              | 'detail_search_container_search_agent_click'
          >
        | undefined
    taggingData: TaggingData
    optimizelyTrackEvent?: string
}

export const CreateSearchAgentButton: FunctionComponent<
    CreateSearchAgentButtonProps &
        Omit<ButtonProps, 'color' | 'width' | 'Icon' | 'onClick'> & {
            size?: ResponsiveValue<ButtonSizeType>
        }
> = ({
    searchAgentCreateLink,
    searchAgentOptionsLink,
    setSearchAgentUIState,
    size = 'medium',
    buttonVariant = 'button',
    testId,
    clickActionEvent,
    taggingData,
    optimizelyTrackEvent,
    ...buttonProps
}) => {
    const { trackCustomDmpEvent } = useContext(DmpStateContext)
    const [profileData] = useProfileData()
    const loginId = isUserLoggedIn(profileData) ? profileData.loginId : undefined
    const isAndroidBrowser = useIsAndroidBrowser()
    const trackEvent = useOptimizelyTrack({ isAndroid: isAndroidBrowser })

    const onClick = async () => {
        trackCustomDmpEvent('UserAction', { eventName: 'TapActivateSearchAgent' })
        if (clickActionEvent) {
            callActionEvent(clickActionEvent, taggingData)
        }
        if (optimizelyTrackEvent) {
            trackEvent(optimizelyTrackEvent)
        }

        if (!loginId) {
            if (storageAvailable('localStorage')) {
                localStorage.setItem(OPEN_CREATE_SEARCH_AGENT_MODAL_STORAGE_KEY, 'true')
            }
            redirectToLoginPage()
        } else {
            if (searchAgentOptionsLink) {
                const searchAgentOptions = await getSearchAgentOptions(searchAgentOptionsLink)
                setSearchAgentUIState({ state: 'showModal', searchAgentOptions: searchAgentOptions })
            } else {
                setSearchAgentUIState({ state: 'showModal' })
            }
        }
    }

    if (!searchAgentCreateLink) {
        return null
    }

    return (
        <Fragment>
            {buttonVariant === 'button' && (
                <Button
                    size={size}
                    color="accent"
                    onClick={onClick}
                    Icon={Searchagent}
                    width="100%"
                    testId={testId}
                    css={hideWhenKeyboardIsOpen}
                    {...buttonProps}
                >
                    Suchagent aktivieren
                </Button>
            )}
            {buttonVariant === 'link' && (
                <ButtonWithLinkStyle
                    fontSize="m"
                    color="palette.coral"
                    fontWeight="bold"
                    onClick={onClick}
                    testId={testId}
                    {...buttonProps}
                >
                    Suchagent aktivieren
                </ButtonWithLinkStyle>
            )}
        </Fragment>
    )
}
