import React, { FunctionComponent } from 'react'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { useMultiSelectCheckboxState } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/useMultiSelectCheckboxState'
import {
    MultiSelectNavigatorLayout,
    NavigatorItemLayoutProps,
} from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/MultiSelectNavigatorLayout'

export const MultiSelectNavigator: FunctionComponent<NavigatorProps & NavigatorItemLayoutProps> = ({
    navigator,
    onSearch,
    'aria-labelledby': ariaLabelledBy,
    tabletItemWidth,
    disabled = false,
    taggingData,
}) => {
    const { checkboxState, handleChange } = useMultiSelectCheckboxState(navigator)

    return (
        <MultiSelectNavigatorLayout
            checkboxState={checkboxState}
            navigator={navigator}
            onChange={handleChange}
            onSearch={onSearch}
            aria-labelledby={ariaLabelledBy}
            tabletItemWidth={tabletItemWidth}
            disabled={disabled}
            taggingData={taggingData}
        />
    )
}
