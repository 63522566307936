import { decodeUriComponentSafely, getFirstString, getPathFromUrl, getQueryParams } from '@wh/common/chapter/lib/urlHelpers'
import { useCallback, useEffect } from 'react'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'

/**
 * Careful when using this, as this method checks which page it is triggered on!
 */
export const useBackButtonSearch = (triggerSearch: SearchCallback, isImageSearch: boolean = false) => {
    // This is super hacky, we create a hardcoded fake contextlink which contains all information from the new page url minus the redundant /iad/
    // this way we can use triggerSearch to update the searchResult without having to resort to the ugly full page reload we had before
    // unfortunately we can't use the beforePopState function of the next.router here as this would overwrite the scrolling restoration fix as next only always allows one callback
    const triggerSearchOnPopState = useCallback(
        (event: { state: { as?: string } | null }) => {
            if (typeof event.state?.as === 'undefined') {
                return
            }

            const pagePath = getPathFromUrl(event.state.as)

            if (isDealerSearchPage(pagePath)) {
                const dealerSearchUri = getDealerSearchUri(pagePath)
                const queryParams = getQueryParams(event.state.as)
                onBackButtonSearch(triggerSearch, dealerSearchUri, queryParams)
            } else if (isResultListPage(pagePath) || isDetailSearchPage(pagePath) || isSellerOrDealerProfile(pagePath)) {
                if (isImageSearch) {
                    const queryParams = getQueryParams(event.state.as)
                    const { pictureKey: rawPictureKey, ...remainingQuery } = queryParams
                    const pictureKey = getFirstString(rawPictureKey)

                    const relativePath = pictureKey ? `/imagesearch/atz/${pictureKey}` : '/imagesearch/atz'
                    onBackButtonSearch(triggerSearch, relativePath, remainingQuery)
                } else {
                    const decodedSeoPath = decodeUriComponentSafely(event.state.as).replace('/iad/', '')

                    onBackButtonSearch(triggerSearch, `/search/atz/seo/${decodedSeoPath}`)
                }
            }
        },
        [triggerSearch, isImageSearch],
    )

    useEffect(() => {
        window.addEventListener('popstate', triggerSearchOnPopState)
        return () => {
            window.removeEventListener('popstate', triggerSearchOnPopState)
        }
    }, [triggerSearch, triggerSearchOnPopState])
}

const onBackButtonSearch = (triggerSearch: SearchCallback, relativePath: string, remainingQuery?: Record<string, string | string[]>) => {
    // We do not want to update the window location when we trigger a search from the back button as
    // we cant be sure that the history url and what the api returns will match (i.e. when coming from startpage)
    // and if they do not this will lead to weird back button behaviour e.g. not going back to expected page
    triggerSearch(
        {
            id: '',
            description: '',
            uri: '',
            serviceName: 'iad',
            relativePath: relativePath,
        },
        remainingQuery,
        { disableUpdateWindowLocation: true },
    )
}

export const isResultListPage = (path: string) => {
    return (/^\/+iad\/+(gebrauchtwagen|immobilien)\/+(?!d\/).+$/.test(path) || isBapResultList(path)) && !isDetailSearchPage(path)
}

export const isBapResultList = (path: string) => {
    return /^\/+iad\/+kaufen-und-verkaufen\/+(marktplatz\/*|l\/|aehnlichkeitssuche).*$/.test(path)
}

const isDetailSearchPage = (path: string) => {
    return /^\/+iad\/+(gebrauchtwagen|immobilien)\/([^/]+)\/detailsuche\/?$/.test(path)
}

export const isSellerOrDealerProfile = (path: string) => {
    return /^\/+iad\/+kaufen-und-verkaufen\/+(verkaeuferprofil|webshop).*$/.test(path)
}

export const isDealerSearchPage = (path: string) => {
    return /^\/+iad\/+(kaufen-und-verkaufen\/+webshop-shop-suche|gebrauchtwagen\/+gebrauchtwagenhaendler|immobilien\/+immobilienmakler).*$/.test(
        path,
    )
}

const getDealerSearchUri = (path: string) => {
    if (path.includes('marktplatz')) {
        return '/search/dealers/5/307'
    } else if (path.includes('gebrauchtwagen')) {
        return '/search/dealers/3/5003'
    } else if (path.includes('immobilien')) {
        return '/search/dealers/2/607'
    } else {
        return ''
    }
}
