import { toast } from '@wh-components/core/Toast/Toast'

export const PERMISSION_DENIED = 1
export const TIMEOUT = 3

export const getCurrentPositionWithToast = (callback: (lat: string, long: string) => void) => {
    getCurrentPosition(callback, (code) => toastLocationError(code))
}

export const getCurrentPosition = (callback: (lat: string, long: string) => void, errorCallback: (code?: number) => void) => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
                const lat = String(position.coords.latitude)
                const lon = String(position.coords.longitude)
                callback(lat, lon)
            },
            (error) => {
                errorCallback(error.code)
            },
            { maximumAge: 0, timeout: 10000, enableHighAccuracy: true },
        )
    } else {
        errorCallback()
    }
}

export const toastLocationError = (code?: number) => {
    switch (code) {
        case PERMISSION_DENIED:
            toastError('Ooops, keine Berechtigung um Standort zu verwenden.')
            break
        case TIMEOUT:
            toastError('Ooops, beim Abrufen des Standorts ist ein Fehler aufgetreten. Bitte versuch es später erneut.')
            break
        default:
            toastError('Ooops, beim Abrufen des Standorts ist ein Fehler aufgetreten.')
    }
}

const toastError = (message: string) => {
    toast(message, {
        type: 'error',
    })
}
