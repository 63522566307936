import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Checkbox, CheckboxSize } from '@wh-components/core/FormElements/Checkbox/Checkbox'
import { NavigatorLabel } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorLabel'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import {
    buildAdditionalParamsFromCheckboxes,
    getFlatPossibleValues,
    getValueId,
    showHitsLabel,
} from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { NavigatorValue } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorValue'
import { ResetAllButton } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ResetAllButton/ResetAllButton'
import { css } from 'styled-components'
import { PossibleNavigatorValue } from '@bbx/search-journey/common/Navigators'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'

interface MultiSelectNavigatorLayoutProps {
    onChange: (label: string, checked: boolean) => Record<string, boolean>
    checkboxState: Record<string, boolean>
    label?: string
    checkboxSize?: CheckboxSize | undefined
    checkedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
    uncheckedContent?: (possibleValue: PossibleNavigatorValue) => React.ReactNode
    sortValues?: (a: PossibleNavigatorValue, b: PossibleNavigatorValue) => number
}

export interface NavigatorItemLayoutProps {
    tabletItemWidth?: string
    phoneItemWidth?: string
}

export const MultiSelectNavigatorLayout: FunctionComponent<NavigatorProps & MultiSelectNavigatorLayoutProps & NavigatorItemLayoutProps> = ({
    navigator,
    onSearch,
    onChange,
    checkboxState,
    label = navigator.label,
    'aria-labelledby': ariaLabelledBy,
    tabletItemWidth = '100%',
    phoneItemWidth = '100%',
    disabled = false,
    checkboxSize,
    checkedContent,
    uncheckedContent,
    sortValues = () => 0,
    taggingData,
}) => {
    const navigatorLabelId = `navigator-title-${navigator.id}`
    const combinedAriaLabelledBy = [ariaLabelledBy, navigatorLabelId].filter(Boolean).join(' ')
    const hasSelectedValue = navigator.selectedValues.length > 0

    const checkboxGroupLayoutCss = css`
        > div {
            width: ${phoneItemWidth};
            display: inline-block;

            ${(p) => p.theme.media.tablet} {
                width: ${tabletItemWidth};
            }
        }
    `

    return (
        <Box>
            <NavigatorLabel id={navigatorLabelId}>{label}</NavigatorLabel>

            <form>
                <Box
                    marginTop={{ phone: 's', tablet: 'inherit' }}
                    css={css`
                        ${checkboxGroupLayoutCss}
                    `}
                >
                    {getFlatPossibleValues(navigator)
                        .sort(sortValues)
                        .map((possibleValue) => {
                            const possibleLabelValueId = getValueId(possibleValue)
                            return (
                                <Checkbox
                                    key={getValueId(possibleValue)}
                                    customCheckboxContent={
                                        uncheckedContent && checkedContent
                                            ? () => ({
                                                  unchecked: uncheckedContent(possibleValue),
                                                  checked: checkedContent(possibleValue),
                                              })
                                            : undefined
                                    }
                                    id={`navigator-${navigator.id}-${possibleValue.label.replace(
                                        /\s/g,
                                        '',
                                    )}-checkbox-${possibleLabelValueId}`}
                                    testId={`navigator-${navigator.id}-${possibleValue.label}-checkbox`}
                                    checked={checkboxState[getValueId(possibleValue)] || false}
                                    disabled={disabled}
                                    onChange={async (e) => {
                                        if (!hasSelectedValue) {
                                            callActionEvent('search_result_list_multiselect', taggingData)
                                        }
                                        const newState = onChange(possibleLabelValueId, e.target.checked)
                                        const additionalParams = buildAdditionalParamsFromCheckboxes(navigator, newState)
                                        await onSearch(navigator.urlConstructionInformation.baseUrl, additionalParams)
                                    }}
                                    paddingBottom={{ phone: 's', tablet: 'xs' }}
                                    label={
                                        <Box paddingLeft={2} width="100%">
                                            <NavigatorValue
                                                value={possibleValue}
                                                id={navigator.id}
                                                aria-labelledby={combinedAriaLabelledBy}
                                                href={possibleValue.webLink?.relativePath}
                                                showHitsLabel={showHitsLabel(navigator)}
                                                disabled={disabled}
                                            />
                                        </Box>
                                    }
                                    size={checkboxSize}
                                />
                            )
                        })}
                </Box>
            </form>
            {navigator.selectedValues.length >= 2 && (
                <ResetAllButton navigatorId={navigator.id} onSearch={onSearch} contextLink={navigator.resetAllInformation?.resetAllUrl} />
            )}
        </Box>
    )
}
