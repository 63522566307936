import React, { FunctionComponent } from 'react'
import { InputSize, TestProps } from '@wh-components/core/common'
import { SearchCallback } from '../SearchCallback'
import { ContextLink, ContextLinkId, ContextLinkList, findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { BaseSelect } from '@wh-components/core/FormElements/Select/Select'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { removeUrlParameter } from '@wh/common/chapter/lib/urlHelpers'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { MarginProps } from '@wh-components/system/space'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { getCurrentPositionWithToast } from '@bbx/search-journey/common/lib/getLocation'

interface SortingProps {
    onSearch: SearchCallback
    sortOrderList: ContextLinkList
    label?: string
    size?: InputSize
    taggingData?: TaggingData
    taggingEvent?: TaggingActionEvent
    taggingEventNearMe?: TaggingActionEvent
}

export const Sorting: FunctionComponent<SortingProps & MarginProps & Required<TestProps>> = ({
    testId,
    onSearch,
    sortOrderList,
    label,
    size = 'small',
    taggingData,
    taggingEvent = 'search_result_list_sortorder',
    taggingEventNearMe = 'search_result_list_sortorder_nearme',
    ...props
}) => {
    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = e.currentTarget.value as ContextLinkId
        const newSortingLink = findContextLinkWithId(selectedId, sortOrderList)

        if (newSortingLink) {
            if (newSortingLink.id === 'distance.ascending') {
                callActionEvent(taggingEventNearMe, taggingData)
                performGeoSearch(newSortingLink, onSearch)
            } else {
                onSearch(newSortingLink)
            }
        }
    }

    return sortOrderList.contextLink.length > 0 ? (
        <Box display="flex" alignItems={{ tablet: 'center' }} flexDirection={{ phone: 'column', tablet: 'row' }} {...props}>
            {label && (
                <Text
                    as="label"
                    htmlFor={testId}
                    fontWeight="semibold"
                    marginRight={{ tablet: 's' }}
                    marginBottom={{ phone: 'xs', tablet: 0 }}
                >
                    {label}
                </Text>
            )}
            <BaseSelect
                id={testId}
                testId={testId}
                size={size}
                items={sortOrderList.contextLink.map((link) => ({ label: link.description, value: link.id }))}
                value={sortOrderList.contextLink.find((link) => link.selected === true)?.id}
                aria-label={!label ? 'Sortierung' : undefined}
                onChange={onChange}
                onClick={() => callActionEvent(taggingEvent, taggingData)}
                icon="up-down-arrow"
            />
        </Box>
    ) : null
}

export const performGeoSearch = (link: ContextLink, onSearch: SearchCallback) => {
    getCurrentPositionWithToast((lat, lon) => {
        const newContextlink = { ...link, relativePath: removeLatAndLon(link.relativePath!) }
        onSearch(newContextlink, { lat: lat, lon: lon })
    })
}

const removeLatAndLon = (path: string) => {
    return removeUrlParameter(removeUrlParameter(path, 'lat'), 'lon')
}
